import React, { FunctionComponent } from 'react';
import { Layout, VenueApp } from '../components';

const VenueAppPage: FunctionComponent = () => (
  <Layout>
    <VenueApp />
  </Layout>
);

export default VenueAppPage;
